import React from 'react';
import logo from '../../assets/svg/art_time_black.svg';
import './Header.scss';
import {Col} from 'reactstrap';

export default class Header extends React.Component {


    render() {
        return (
            <div>
                <Col md={3} className='header'>
                    <img src={logo} className="logo" alt="logo"/>
                    <h1 className="title">Timeworks</h1>
                </Col>
                <Col md={9}/>
            </div>
        )
    }
}