import React from 'react';
import './OpenSource.scss';
import {Col, Row} from "reactstrap";
import GitHubIcon from '../../assets/svg/git_hub.svg';

export default class OpenSource extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className='productivityBooster'>
                    <Row>
                        <Col md={{size: 8, offset: 2}} className='booster'>
                            <h1 className='title'>An open source project</h1>
                            <h3 className='description'>Timeworks is developed and maintained by Artezio. It is
                                permissively
                                licensed under BSD. Use it freely in personal or commercial projects! </h3>
                        </Col>
                    </Row>

                    <div className='gitButton' onClick={() => {
                        window.open('https://github.com/Artezio/ART-TIME')
                    }}>
                        <div className='icon'>
                            <img src={GitHubIcon} alt="git hub"/>
                        </div>
                        <div className='buttonName'>
                            Source Code GitHub
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}