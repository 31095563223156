import React from 'react';
import './ProductivityBooster.scss';
import './CustomCard.scss';
import {CarouselIndicators, Col, Row} from 'reactstrap';
import Slider from "../slider/Slider";
import {CARD_ITEMS} from "../../constants/cardItemConstants";
import {SLIDER_ITEMS} from "../../constants/sliderConstants";

function CustomCard(props) {
    return <div className='customCard'>
        <hr className='line'/>
        <div className='title'>
            {props.title}
        </div>
        <div className='content'>
            {props.children}
        </div>
    </div>;
}

export default class ProductivityBooster extends React.Component {

    constructor(state) {
        super(state);
        this.state = {
            activeIndex: 0
        }
    }

    setActiveItem = (item) => {
        this.setState({
            activeIndex: item
        })
    };

    render() {
        return (
            <React.Fragment>
                <div className='productivityBooster'>
                    <Row>
                        <Col md={{size: 8, offset: 2}} className='booster'>
                            <h1 className='title'>Timeworks is a productivity booster</h1>
                            <h3 className='description'>Monitor the workload of a department or a particular employee at
                                a glance</h3>
                        </Col>
                    </Row>
                    <Row className='slider'>
                        <Col xl={4} md={10} className='offset-md-1 offset-xl-0'>
                            <CustomCard title={CARD_ITEMS[this.state.activeIndex].caption}>
                                {CARD_ITEMS[this.state.activeIndex].description}
                            </CustomCard>
                        </Col>
                        <Col xl={8} md={10} className='offset-md-1 offset-xl-0'>
                            <Slider setActiveItem={this.setActiveItem} activeIndex={this.state.activeIndex}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CarouselIndicators items={SLIDER_ITEMS} activeIndex={this.state.activeIndex}
                                                onClickHandler={this.setActiveItem}/>
                        </Col>
                    </Row>
                    <hr className='divider'/>
                </div>
            </React.Fragment>
        )
    }
}