import React from 'react';
import GetStarted from "../../components/getStarted/GetStarted";
import ProductivityBooster from "../../components/productivityBooster/ProductivityBooster";
import Features from "../../components/features/Features";
import Why from "../../components/whyComponent/Why"
import OpenSource from "../../components/openSourceComponent/OpenSource";
import Footer from "../../components/footer/Footer";
import './main.scss';
import Header from "../../components/header/Header";

export default class Main extends React.Component {


    render() {
        return (
            <React.Fragment>
                <div className='container'>
                    <Header/>
                    <GetStarted/>
                </div>
                <div className='backGray'>
                    <div className='container'>
                        <ProductivityBooster/>
                        <Features/>
                    </div>
                </div>
                <div className='container'>
                    <Why/>
                </div>
                <div className='backGray'>
                    <div className='container'>
                        <OpenSource/>
                    </div>
                </div>
                <div className="container">
                    <Footer/>
                </div>
            </React.Fragment>
        )
    }
}