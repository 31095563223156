export const CARD_ITEMS =
    [{
        caption: 'Define Custom Working Schedule and Types of Hours',
        description: 'Distributed international teams might have difficulties in time tracking since weekends and public holidays vary in different countries. \n' +
            'Timeworks allows to create custom calendars relevant for different countries.'
    },
        {
            caption: 'Simplify Challenging Tasks: Create Projects and Subprojects',
            description: 'It is possible to create projects and include any number of subprojects into them. \n' +
                'Employees can be assigned, moved or deleted from projects at any time.'
        },
        {
            caption: 'Adjustable and Detailed Timesheets',
            description: 'Timeworks carefully tracks daily, weekly and monthly working hours and reflects any changes; it also allows to view timesheets covering a custom period of time.'
        },
        {
            caption: 'Resolve Timing Issues Seamlessly',
            description: 'Timeworks displays the amount of total working hours in a month based on the data gathered from calendar settings. If an employee reports less or more hours, the system displays a warning notification and sends out an e-mail offering to review the time log.'
        },
        {
            caption: 'Custom Filters for Effective Team Management',
            description: 'Managers have access to a lot of data in Timeworks but there is no need to handle all the information manually. Filtering option provides simple and convenient tools to sort hours by offices, projects, employees, etc. '
        },
        {
            caption: 'Generate Report and Invoices On the Fly',
            description: 'Timeworks automatically structures all the data in convenient and easy to read reports for customers or internal tracking. Various types of reports:\n' +
                'Report by time balance; Report by employees; Project report; Days report'
        },
        {
            caption: 'Import and Export Data Safely',
            description: 'User data importing is implemented utilizing LDAP/Active Directory.\n' +
                'Import settings are defined individually for each single project.\n' +
                'It is also possible to run export/import processes using SOAP/HTTP system interface.'
        }];