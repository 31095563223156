import React from 'react';
import './Slider.scss';
import {SLIDER_ITEMS} from '../../constants/sliderConstants';

import {
    Carousel,
    CarouselItem,
    CarouselControl
} from 'reactstrap';

export default class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {activeIndex: 0};
    }

    onExiting = () => {
        this.animating = true;
    };

    onExited = () => {
        this.animating = false;
        this.props.setActiveItem(this.props.activeIndex);
    };

    next = () => {
        if (this.animating) return;
        const nextIndex = this.props.activeIndex === SLIDER_ITEMS.length - 1 ? 0 : this.props.activeIndex + 1;
        this.props.setActiveItem(nextIndex);
    };

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.props.activeIndex === 0 ? SLIDER_ITEMS.length - 1 : this.props.activeIndex - 1;
        this.props.setActiveItem(nextIndex);
    };


    render() {
        const {activeIndex} = {activeIndex: this.props.activeIndex};

        const slides = SLIDER_ITEMS.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img className='item' src={item.src} alt={item.altText}/>
                </CarouselItem>
            );
        });

        return (
            <div>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
                </Carousel>
            </div>
        );
    }
}