import React from 'react';
import {Col, Row} from 'reactstrap';
import './Footer.scss';
import Icon from '../../assets/svg/icon-primary.svg'

export default class Footer extends React.Component {
    render() {
        return (
            <div className='footer'>
                <Row>
                    <Col md={12}>
                        <h1 className='sponsored'>
                            Sponsored by Artezio
                        </h1>
                    </Col>
                </Row>
                <div className='about'>
                    <img src={Icon} alt='' className='logo'/>
                    <p>
                        <a href='https://artezio.com' target='_blank' rel="noopener noreferrer">Artezio</a> is a team of
                        enthusiastic and technology-driven professionals who assist businesses
                        to
                        go digital. Since establishment in 2000, we have completed over 1000 projects and become a
                        trusted partner for many businesses. We continuously strive to achieve the best results in
                        delivering robust solutions and learned to work in the most efficient way possible. We are
                        ready
                        to share our knowledge and expertise in time and resources management for you to keep the
                        track
                        of your business processes.
                    </p>
                </div>
                <div className='let'>
                    Don’t hesitate and let Timeworks take care of time management for your business today!
                </div>
                <hr className='divider'/>
                <Row className='letRow'>
                    <Col md={7}>
                        <h5 className='let'><br/>Let our experts work with you and create engaging applications that
                            will
                            bring your business to the next level.</h5>
                    </Col>
                    <Col md={5}/>
                </Row>
                <div className='copyright'>
                    2000 - 2023 Artezio LLC, All rights reserved
                </div>
            </div>
        )
    }

}