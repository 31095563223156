import React from 'react';
import './GetStarted.scss';
import {Col, Row} from 'reactstrap';
import GetStartedImg from '../../assets/svg/pic.svg';
import DockerIcon from "../../assets/svg/docker_hub.svg";

export default class GetStarted extends React.Component {


    render() {
        return (
            <Row className='startedComponent'>
                <Col md={6} className="getStarted">
                    <h1 className="blockTitle">Streamline your work
                        and turn time into money</h1>
                    <p>Start tracking working hours, productivity and efficiency of your team with a FREE,
                        open-source, user-friendly and functional solution aimed to simplify business time
                        management.</p>
                    <div className='dockerButton' onClick={() => {
                        window.open('https://hub.docker.com/r/arteziollc/art-time')
                    }}>
                        <div>
                            <img src={DockerIcon} alt="docker hub" className='icon'/>
                        </div>
                        <div className='buttonName'>
                            GET STARTED
                        </div>
                    </div>
                </Col>
                <Col md={{size:6}} className='imageBlock'>
                    <img src={GetStartedImg} alt=""/>
                </Col>
            </Row>
        )
    }
}