import FirstSlide from "../assets/png/firstSlide.png";
import SecondSlide from "../assets/png/secondSlide.png";
import ThirdSlide from "../assets/png/thirdSlide.png";
import FourthSlide from "../assets/png/fourthSlide.png";
import FifthSlide from "../assets/png/fifthSlide.png";
import SixthSlide from "../assets/png/sixthSlide.png";
import LastSlide from "../assets/png/lastSlide.png";

export const SLIDER_ITEMS = [
    {
        src: FirstSlide
    },
    {
        src: SecondSlide
    },
    {
        src: ThirdSlide
    },
    {
        src: FourthSlide
    },
    {
        src: FifthSlide
    },
    {
        src: SixthSlide
    },
    {
        src: LastSlide
    }
];