import React from 'react';
import {Col, Row} from 'reactstrap';
import './Why.scss';
import '../productivityBooster/ProductivityBooster.scss';
import './ReasonItem.scss'
import {REASONS} from "../../constants/whyComponentConstants";

function ReasonItem(props) {
    return <div className='listItem'>
        <div className='title'>
            {props.title}
            <div className='content'>
                {props.children}
            </div>
        </div>
    </div>;

}

export default class Why extends React.Component {

    renderList = () => {
        return REASONS.map((item, i) => {
            return (
                <ReasonItem key={`${i}_reasons`} title={item.title}>
                    {item.content}
                </ReasonItem>
            )
        })
    };

    render() {
        return (
            <div className='whyComponent'>
                <Row>
                    <Col md={3}>
                        <hr className='line'/>
                        <h1 className='componentTitle'>
                            Why Timeworks
                        </h1>
                    </Col>
                    <Col md={9}/>
                </Row>
                <Row className='reasons'>
                    <Col md={3}>
                        <h2 className='listTitle'>
                            Timeworks Meets Needs of each Participant of your Business
                        </h2>
                    </Col>
                    <Col md={9} className='list'>
                        {this.renderList()}
                    </Col>
                </Row>
            </div>
        )
    }
}