import Calendars from '../assets/svg/calendars.svg';
import Reports from '../assets/svg/reports.svg';
import System from '../assets/svg/system.svg';
import Efficiency from '../assets/svg/efficiency.svg';
import Friendly from '../assets/svg/friendly.svg';
import Productivity from '../assets/svg/productivity.svg'

export const FEATURES = [
    {
        iconSrc: Calendars,
        title: 'Create Custom Calendars',
        content: 'Create custom calendars relevant for different countries and define all types of working hours.'
    },
    {
        iconSrc: Reports,
        title: 'Generate Reports',
        content: 'Timeworks automatically structures all the data in convenient and easy to read reports.'
    },
    {
        iconSrc: System,
        title: 'Customize The System',
        content: 'Create custom reports and apply custom filters for effective team management.'
    },
    {
        iconSrc: Efficiency,
        title: 'Evaluate Team’s Efficiency',
        content: 'Track team’s activity and engagement of each member on the project.'
    },
    {
        iconSrc: Friendly,
        title: 'User-friendly',
        content: 'Easy-to-use and fast way to track time and improve efficiency no questions asked.'
    },
    {
        iconSrc: Productivity,
        title: 'Boost Your Productivity',
        content: 'Monitor the workload and identify which tasks are slowing the work down.'
    }
];