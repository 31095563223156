export const REASONS = [
    {
        title: 'Manager',
        content: 'Track efficiency and productivity of the business processes and improve business and project profitability.'
    },
    {
        title: 'HR Specialist',
        content: 'Create custom calendars and reports, evaluate team’s  productivity and attendance, track employees’ individual development plan fulfillment.'
    },
    {
        title: 'CEO',
        content: 'Get an accurate view of how the business model works and where the time and resources go, distinguish what changes can be made to improve the metrics.'
    },
    {
        title: 'System Administrator',
        content: 'The platform can be used for multiple office locations and works both in cloud or on your own server. It integrates with Active Directory and your system infrastructure.'
    },
    {
        title: 'Employee',
        content: 'Track your activity and engagement on the project and how much you contribute to the project success.'
    }
];