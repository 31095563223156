import React from 'react';
import Main from './containers/main/main';

function App() {
  return (
    <React.Fragment>
        <Main/>
    </React.Fragment>
  );
}

export default App;
