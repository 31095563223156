import React from 'react';
import '../productivityBooster/ProductivityBooster.scss';
import './ConcreteFeature.scss';
import {Col, Row} from 'reactstrap';
import {FEATURES} from '../../constants/concreteFeatureConstants';

function ConcreteFeature(props) {
    return <Col sm={{size: 5, offset: 1}} className='concreteFeature'>
        <img className='icon' src={props.iconSrc} alt={props.title}/>
        <div className='title'>
            {props.title}
            <div className='content'>
                {props.children}
            </div>
        </div>
    </Col>;

}

export default class Features extends React.Component {

    renderFeatures = () => {
        return FEATURES.map((item, i) => {
            return (
                <ConcreteFeature key={`${i}_features`} iconSrc={item.iconSrc} title={item.title}>
                    {item.content}
                </ConcreteFeature>
            )
        })
    };

    render() {
        return (
            <React.Fragment>
                <div className='productivityBooster'>
                    <Row className='featuresRow'>
                        <Col md={{size: 8, offset: 2}} sm={12} className='booster'>
                            <h1 className='title'>All features you need in one place!</h1>
                            <h3 className='description'>Your comfort and security of data are the top priorities for
                                Timeworks.
                                User authentication process is handled via corporate SSO (OAuth and SAML protocols) or
                                LDAP/Active Directory. </h3>
                        </Col>
                    </Row>
                    <Row>
                        {this.renderFeatures()}
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}